import React, { useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";

export default function ImageInput({
  label,
  name,
  value = null,
  setFieldValue,
  errors,
  touched,
}) {
  const inputRef = useRef(null);
  const [image, setImage] = useState(value);
  const validFileExtensions = { image: ["jpg", "png", "jpeg", "webp"] };
  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }
  const onImageSelected = (e) => {
    if (isValidFileType(e.target.files[0].name, "image")) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFieldValue(name, e.target.files[0]);
    } else {
      toast.error("Only jpg, jpeg, png, webp files are allowed!");
    }
  };

  return (
    <div className="image-placeholder mb-3">
      {label && <label className="mb-1">{label}</label>}
      <button
        type="button"
        className="d-block p-0"
        onClick={() => inputRef.current.click()}
      >
        {image && (
          <span>
            <MdEdit color="#fff" />
          </span>
        )}
        {image ? <img src={image} alt="" /> : <FaPlus size={15} />}
      </button>
      <input
        ref={inputRef}
        type="file"
        name={name}
        accept="image/jpg, image/png, image/jpeg"
        onChange={onImageSelected}
        className="d-none"
      />
      {errors && touched && <p className="text-danger mt-1 mb-0">{errors}</p>}
    </div>
  );
}
