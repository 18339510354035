import React from "react";

export default function Titlebar({ title, children }) {
  return (
    <div className="title-bar d-flex flex-column flex-lg-row align-items-between align-items-lg-center justify-content-between gap-4 mb-4">
      <h2 className="heading-blue ms-5 ms-lg-0 ps-2 ps-lg-0">{title}</h2>
      <div>{children}</div>
    </div>
  );
}
