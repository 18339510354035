import axios from "axios";
import BaseService from "./BaseService";
import { BASE_URL } from "../axios";

export default class AgentService extends BaseService {
  static endPoint = "agent";

  static seEndPoint(url) {
    this.endPoint = `agent/${url}`;
  }
  static removeEndPoint(url) {
    this.endPoint = `agent`;
  }

  static updateImage(id, updateUrl, body) {
    axios.patch(`${BASE_URL}${this.endPoint}/${updateUrl}/${id}`, body);
  }
}
