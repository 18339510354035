import React, { useState } from "react";
import NavMenu from "./NavMenu";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";

export default function Sidebar() {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <>
      <Button
        className="sidebar-btn d-block d-lg-none position-absolute mt-4 ms-4 mt-lg-0 ms-lg-0"
        onClick={toggleShow}
      >
        <HiMenu />
      </Button>
      <div className={`sidebar p-4 ${!show && "d-none d-lg-block"} bg-dark`}>
        <Button
          className="sidebar-btn d-block d-lg-none position-absolute top-0 end-0 mt-2 me-2"
          onClick={toggleShow}
        >
          <FaTimes />
        </Button>
        <div className="d-flex align-items-center gap-3 mb-4 mt-3 mt-md-0">
          {/* <img className="logo" src="/images/logo1.png" alt="" /> */}
<img className="logo" src="/images/logo.png" alt="" />

          <div>
            <h5 className="heading-blue mb-0 text-white">MORTGAGE</h5>
            <span className="heading-blue mb-0 text-white">DASHBOARD</span>
          </div>
        </div>
        <NavMenu />
      </div>
    </>
  );
}




