import React, { useState } from "react";
import { ResetPassword, SendEmail, VerifyOTP } from "../components";
import { PublicLayout } from "../layouts";

export default function ForgotPassword() {
  const [activeStep, setActiveStep] = useState(0);

  const onNextStep = () => setActiveStep(activeStep + 1);

  const steps = [
    <SendEmail onNextStep={onNextStep} />,
    <VerifyOTP onNextStep={onNextStep} />,
    <ResetPassword />,
  ];

  return (
    <PublicLayout>
      <h2 className="heading-blue mb-3">Forgot Password?</h2>
      {steps[activeStep]}
    </PublicLayout>
  );
}
