import React from "react";
import { Route } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  Dashbaord,
  Agents,
  Contacts,
  AgentInquiries,
  Blogs,
  AddBlog,
  EditBlog,
  ApplyForm,
  Pages,
  AddRate,
  EditRate,
  GetRate,
  News,
  AddNews,
  EditNews,
} from "../pages";
import { GuestRoute, ProtectedRoute } from "../components";

export const getRoutes = () => {
  const routes = [
    {
      path: "/login",
      element: (
        // <GuestRoute>
          <Login />
        // </GuestRoute>
        
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <GuestRoute>
          <ForgotPassword />
        </GuestRoute>
      ),
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Dashbaord />
        </ProtectedRoute>
      ),
    },
    {
      path: "/applyform",
      element: (
        <ProtectedRoute>
          <ApplyForm />
        </ProtectedRoute>
      ),
    },
    {
      path: "/agents",
      element: (
        <ProtectedRoute>
          <Agents />
        </ProtectedRoute>
      ),
    },
    {
      path: "/contacts",
      element: (
        <ProtectedRoute>
          <Contacts />
        </ProtectedRoute>
      ),
    },
    {
      path: "/agent-inquiries",
      element: (
        <ProtectedRoute>
          <AgentInquiries />
        </ProtectedRoute>
      ),
    },
    {
      path: "/pages",
      element: (
        <ProtectedRoute>
          <Pages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/blogs",
      element: (
        <ProtectedRoute>
          <Blogs />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-blog",
      element: (
        <ProtectedRoute>
          <AddBlog />
        </ProtectedRoute>
      ),
    },
    {
      path: "/blog/:id",
      element: (
        <ProtectedRoute>
          <EditBlog />
        </ProtectedRoute>
      ),
    },

    {
      path: "/news",
      element: (
        <ProtectedRoute>
          <News />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-news",
      element: (
        <ProtectedRoute>
          <AddNews />
        </ProtectedRoute>
      ),
    },
    {
      path: "/news/:id",
      element: (
        <ProtectedRoute>
          <EditNews />
        </ProtectedRoute>
      ),
    },

    {
      path: "/rate",
      element: (
        <ProtectedRoute>
          <GetRate />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-rate",
      element: (
        <ProtectedRoute>
          <AddRate />
        </ProtectedRoute>
      ),
    },
    {
      path: "/rate/:id",
      element: (
        <ProtectedRoute>
          <EditRate />
        </ProtectedRoute>
      ),
    },

    // {
    //   path: "/content",
    //   element: (
    //     <ProtectedRoute>
    //       <Content />
    //     </ProtectedRoute>
    //   ),
    // },
    // {
    //   path: "/add-content",
    //   element: (
    //     <ProtectedRoute>
    //       <AddContent />
    //     </ProtectedRoute>
    //   ),
    // },
    // {
    //   path: "/content/:id",
    //   element: (
    //     <ProtectedRoute>
    //       <EditContent />
    //     </ProtectedRoute>
    //   ),
    // },
  ];
  return (
    <>
      {routes.map((item, i) => {
        return <Route key={i} path={item.path} element={item.element} />;
      })}
    </>
  );
};
