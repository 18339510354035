import React, { useState } from "react";
import CModal from "./CModal";
import { IconButton } from "./buttons";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

export default function DeleteModal({ itemName, id, onDelete }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);

  const onDeleteItem = () => {
    handleClose();
    onDelete(id);
  };

  return (
    <>
      <IconButton icon={<FaTrash className="text-danger"/>} onClick={handleClose} />
      <CModal
        title={"Delete " + itemName}
        show={show}
        handleClose={handleClose}
        onFormSubmit={onDeleteItem}
        submitText="Delete"
      >
        {"Are you sure you want to delete this " + itemName + "?"}
      </CModal>
    </>
  );
}
