import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../layouts";
import {
  DeleteModal,
  Input,
  Loader,
  Titlebar,
  ViewApplyFormModal,
} from "../components";
import DataTable from "react-data-table-component";
import { HiOutlineSearch } from "react-icons/hi";
import { ASSETS_BASE_URL, AgentService, ApplyFormService } from "../service";
import { toast } from "react-toastify";

export default function ApplyForm() {
  const [pending, setPending] = useState(true);
  const [contacts, setContacts] = useState([]);
  const[filteredContacts, setFilteredContacts] = useState([]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.contactInfo.fullName,
    },
    {
      name: "Email",
      selector: (row) => row.contactInfo.email,
    },
    {
      name: "Phone",
      selector: (row) => row.contactInfo.phone,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <ViewApplyFormModal data={row} />
            <DeleteModal itemName="Contact" id={row._id} onDelete={onDelete} />
          </div>
        );
      },
    },
  ];

  const onSearchRecord = (e) => {
    let newData = contacts.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredContacts(newData);
  };

  const onDelete = async (id) => {
    try{
      await ApplyFormService.delete(id);
      let list = contacts.filter((item) => item._id != id);
      setContacts(list);
      list = filteredContacts.filter((item) => item._id != id);
      setFilteredContacts(list);
      toast.success("Form successfully deleted!");
    } catch(error){
      toast.error("Error while deleting agent data!");
    }
  }

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        let response = await ApplyFormService.get();
        let result = response.data.data;
        setContacts(result);
        setFilteredContacts(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      } finally {
        setPending(false);
      }
    };
    fetchContacts();
  }, []);

  return (
    <ProtectedLayout>
      <Titlebar title="Message">
        {/* <div className="d-flex gap-3 align-items-center justify-content-end">
          <Input
            icon={<HiOutlineSearch size={24} />}
            placeholder="Search Title..."
            onChange={onSearchRecord}
            mainClass="mb-0"
          />
        </div> */}
      </Titlebar>
      <DataTable
        columns={columns}
        data={filteredContacts}
        progressPending={pending}
        progressComponent={<Loader />}
        pagination
        responsive
      />
    </ProtectedLayout>
  );
}
