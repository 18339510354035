import React, { useState } from "react";
import { CModal, IconButton } from "../common";
import { FaEye } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";

export default function ViewApplyFormModal({ data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);

  let oldDate = data.createdAt;
  let date = oldDate.substring(0,10);

  return (
    <>
      <IconButton icon={<FaEye className="text-success"/>} onClick={handleClose} />
      <CModal
        title="ApplyForm Details"
        show={show}
        handleClose={handleClose}
        hasFooter={false}
      >
        <Row>
          <Col md="6">

            <p className="mb-2">
          <span className="fw-semibold">ID: </span>
          {data._id}
        </p>

        <p className="mb-2">
          <span className="fw-semibold">Email: </span>
          {data.contactInfo.email}
        </p>

        <p className="mb-2">
          <span className="fw-semibold">Mortgage Type: </span>
          {data.mortgageType}
        </p>

        <p className="mb-2">
          <span className="fw-semibold">Fund Required: </span>
          {data.fundRequired}
        </p>

        <p className="mb-2">
          <span className="fw-semibold">Living Of Applicant: </span>
          {data.noOfApplicant}
        </p>

        </Col>
          <Col md="6">

          <p className="mb-2">
          <span className="fw-semibold">Name: </span>
          {data.contactInfo.fullName}
        </p>
       
        <p className="mb-2">
          <span className="fw-semibold">Province: </span>
          {data.contactInfo.province}
        </p>
        
        <p className="mb-2">
          <span className="fw-semibold">Mortgage Amount: </span>
          {data.mortgageAmount}
        </p>
        
        <p className="mb-2">
          <span className="fw-semibold">Living Status: </span>
          {data.livingStatus}
        </p>
        <p className="mb-2"> 
          <span className="fw-semibold">Data: </span>
          {date}
        </p>

          </Col>
        </Row>
        
       
       
        {/* {
          data.message && data.message !== "NA" && <>
          <span className="fw-semibold">Message: </span>
          <p className="mb-2">{data.message}</p>
        </>
        } */}
      </CModal>
    </>
  );
}
