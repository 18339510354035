import React from "react";
import { Input } from "../common";
import { Button } from "react-bootstrap";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { useFormik } from "formik";
import { sendEmailSchema } from "../../utils/schemas";

export default function SendEmail({ onNextStep }) {
  const onSendEmail = () => {
    onNextStep();
  };

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: sendEmailSchema,
    onSubmit: onSendEmail,
  });
  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Email"
        icon={<HiOutlineEnvelope size={30} />}
        placeholder="Enter Registered Email"
        name="email"
        value={values.email}
        onChange={handleChange}
        errors={errors.email}
        touched={touched.email}
      />
      <div className="text-center">
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}
