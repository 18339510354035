import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default function VerifyOTP({ onNextStep }) {
  const [number, setNumber] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  });
  const [error, setError] = useState(null);

  const handleNumber = (event) => {
    const result = event.target.value.replace(/[^0-9]/, "");
    setNumber({
      ...number,
      [event.target.name]: result,
    });

    const { maxLength, value, name } = event.target;
    const fieldIndex = name.slice(-1);
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=digit${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.values(number).some((digit) => digit === "" || digit === null)) {
      setError("Fill in all digits!");
    } else {
      onNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="otp-form">
      <p>We have sent a code to your email to verify that it's really you.</p>
      <label className="mb-1">Enter OTP</label>
      <div className="d-flex align-items-center gap-4 mb-3">
        <input
          type="text"
          name="digit1"
          value={number.digit1}
          maxLength={1}
          onChange={handleNumber}
          className="form-control fs-2 text-center py-3"
          autoFocus={true}
        />
        <input
          type="text"
          name="digit2"
          value={number.digit2}
          maxLength={1}
          onChange={handleNumber}
          className="form-control fs-2 text-center py-3"
        />
        <input
          type="text"
          name="digit3"
          value={number.digit3}
          maxLength={1}
          onChange={handleNumber}
          className="form-control fs-2 text-center py-3"
        />
        <input
          type="text"
          name="digit4"
          value={number.digit4}
          maxLength={1}
          onChange={handleNumber}
          className="form-control fs-2 text-center py-3"
        />
      </div>
      {error && <p className="text-danger">{error}</p>}
      <div className="text-center">
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}
