import * as yup from "yup";
export * from "./authentication";

export const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid Email!").required("Email is required!"),
  password: yup
    .string()
    .min(8, "Password must be atleast 8 characters long!")
    .required("Password is required!"),
});

// export const sendEmailSchema = yup.object().shape({
//   email: yup.string().email("Invalid Email!").required("Email is required!"),
// });

// export const resetPassSchema = yup.object().shape({
//   password: yup
//     .string()
//     .min(8, "Password must be atleast 8 characters long!")
//     .required("Password is required!"),
//   confirmPassword: yup
//     .string()
//     .oneOf([yup.ref("password"), null], "Passwords must match!"),
// });

export const staffSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  cnic: yup.string().matches(/^[0-9]{5}-[0-9]{7}-[0-9]$/, "Invalid CNIC!"),
  salary: yup.number("Salary must be in numbers!"),
  email: yup.string().email("Invalid Email!").required("Email is required!"),
  phone: yup
    .string()
    .matches(/^\d{11}$/, "Only digits & maximum 11 allowed!")
    .required("Phone is required!"),
  role: yup.string().required("Role is required!"),
  note: yup.string(),
  joiningDate: yup.string().required("Joining Date is required!"),
  employmentType: yup.string().required("Employment Type is required!"),
  status: yup.string(),
  leavingDate: yup.string().when("status", {
    is: (status) => status === "inactive",
    then: () => yup.string().required("Leaving Date is required!"),
    otherwise: () => yup.string(),
  }),
});

export const vehicleSchema = yup.object().shape({
  brand: yup.string().required("Brand is required!"),
  number: yup.string().required("Number is required!"),
  model: yup.string(),
  note: yup.string(),
});

export const routeSchema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  startPoint: yup.string().required("Start Point is required!"),
  endPoint: yup.string().required("End Point is required!"),
});

export const subRouteSchema = yup.object().shape({
  title: yup.string().required("Title is required!"),
});

export const productSchema = yup.object().shape({
  image: yup.mixed().required("Image is required!"),
  title: yup.string().required("Title is required!"),
  category: yup.string().required("Category is required!"),
  price: yup.number().required("Price is required!"),
  onDelivery: yup.boolean().required("Delivery Availability is required!"),
  onCounter: yup.boolean().required("Counter Availability is required!"),
});

export const productCategorySchema = yup.object().shape({
  title: yup.string().required("Title is required!"),
});

export const customerSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  email: yup.string().email("Invalid Email!").required("Email is required!"),
  phone: yup
    .string()
    .matches(/^\d{11}$/, "Only digits & maximum 11 allowed!")
    .required("Phone is required!"),
});

export const expenseSchema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  amount: yup.number().required("Amount is required!"),
  category: yup.string().required("Category is required!"),
  description: yup.string(),
});

export const payoutSchema = yup.object().shape({
  staffId: yup.string().required("Staff Member is required!"),
  salary: yup.number().required("Salary is required!"),
  hasBonus: yup.boolean(),
  bonusAmount: yup.string().when("hasBonus", {
    is: true,
    then: () => yup.string().required("Bonus is required!"),
  }),
  hasDeduction: yup.boolean(),
  deductionAmount: yup.string().when("hasDeduction", {
    is: true,
    then: () => yup.string().required("Deduction is required!"),
  }),
  note: yup.string(),
});

// Mortgage Project
export const agentSchema = yup.object().shape({
  name: yup.string().required("Name is required!"),
  email: yup.string().required("Email is required!"),
  phone: yup.string().required("Phone is required!"),
  license: yup.string().required("License is required!"),
  image: yup.mixed().required("Image is required!"),
  description: yup.string(),
});

export const blogSchema = yup.object().shape({
  title: yup.string().required("Title is required!"),
  image: yup.mixed().required("Image is required!"),
  description: yup.string().required("Description is required!"),
});

export const newsSchema = yup.object().shape({
  heading: yup.string().required("Headin is required!"),
  image: yup.mixed().required("Image is required!"),
  description: yup.string().required("Description is required!"),
});

export const rateSchema = yup.object().shape({
  rate: yup.number().required("Rate is required!"),
});

export const pageSchema = yup.object().shape({
  description: yup.string().required("Description is required!"),
});