import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../layouts";
import { DataIconBox, Titlebar } from "../components";
import { toast } from "react-toastify";
import { Row } from "react-bootstrap";
import { FaEdit, FaUsers, FaUserTie } from "react-icons/fa";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  AgentService,
  BlogService,
  AgentContactService,
  ApplyFormService,
} from "../service";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashbaord() {
  const [agents, setAgents] = useState(0);
  const [agentInquiries, setAgentInquiries] = useState(0);
  const [blogs, setBlogs] = useState(0);
  const [form, setForm] = useState(0);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        let response = await AgentService.getByEndPoint("getAgent");
        console.log("My Response : ", response);
        let result = response.data.data.length;
        setAgents(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      }
    };
    fetchAgents();

    const fetchInquiries = async () => {
      try {
        let response = await AgentContactService.get();

        let result = response.data.data.length;
        setAgentInquiries(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      }
    };
    fetchInquiries();

    const fetchBlogs = async () => {
      try {
        let response = await BlogService.get();
        let result = response.data.data.length;
        setBlogs(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      }
    };
    fetchBlogs();

    const fetchForm = async () => {
      try {
        let response = await ApplyFormService.get();
        let result = response.data.data.length;
        setForm(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      }
    };
    fetchForm();
  }, []);

  const cardsData = [
    {
      icon: <FaUsers className="menu-icon text-success " />,
      amount: agents,
      title: "Total Agents",
    },
    {
      icon: <FaUserTie className="menu-icon" style={{ color: "#009ef7" }} />,
      amount: agentInquiries,
      title: "Agents Inquiries",
    },
    {
      icon: <FaEdit className="menu-icon text-warning" />,
      amount: blogs,
      title: "Total Blogs",
    },
    {
      icon: <FaEdit className="menu-icon text-danger" />,
      amount: form,
      title: "Total Apply Form",
    },
  ];

  // const options = {
  //   plugins: {
  //     title: {
  //       display: false,
  //     },
  //   },
  //   responsive: true,
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       stacked: true,
  //     },
  //   },
  // };

  // const chartOpts = [
  //   { label: "This Month", value: "month" },
  //   { label: "This Week", value: "week" },
  // ];
  // const weekLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  // const monthLabels = [
  //   "Day 1-5",
  //   "Day 6-10",
  //   "Day 11-15",
  //   "Day 16-20",
  //   "Day 21-25",
  //   "Last days",
  // ];
  // const expensesData = {
  //   weekly: [8000, 5000, 11000, 3000, 5000, 9000, 4000],
  //   monthly: [11000, 12000, 10000, 5000, 15000, 9000],
  // };
  // const profitData = {
  //   weekly: [2000, 4000, 3000, 1000, 2000, 5000, 3000],
  //   monthly: [7000, 10000, 4000, 8000, 3000, 7000],
  // };
  // const [labels, setLabels] = useState(monthLabels);
  // const [expenses, setExpenses] = useState(expensesData.monthly);
  // const [profit, setProfit] = useState(profitData.monthly);

  // const chartData = {
  //   labels,
  //   datasets: [
  //     {
  //       label: "Expenses",
  //       data: expenses,
  //       backgroundColor: "#b7cdff",
  //       stack: "Stack 0",
  //     },
  //     {
  //       label: "Profit",
  //       data: profit,
  //       backgroundColor: "#b7ff6f",
  //       stack: "Stack 1",
  //     },
  //   ],
  // };

  // const onChartTypeSelected = (opt) => {
  //   if (opt.value === "month") {
  //     setLabels(monthLabels);
  //     setExpenses(expensesData.monthly);
  //     setProfit(profitData.monthly);
  //   } else {
  //     setLabels(weekLabels);
  //     setExpenses(expensesData.weekly);
  //     setProfit(profitData.weekly);
  //   }
  // };

  return (
    <ProtectedLayout>
      <Titlebar title="Welcome to Deltaf Mortgage!" />
      {/* <div className="d-flex flex-wrap align-items-center gap-3 mb-4"> */}
      <Row>
        {cardsData.map((item, i) => {
          return (
            <DataIconBox
              key={i}
              icon={item.icon}
              amount={item.amount}
              title={item.title}
            />
          );
        })}
      </Row>
      {/* </div> */}
    </ProtectedLayout>
  );
}
