import axios from "axios";
import BaseService from "./BaseService";
import { BASE_URL } from "../axios";

export default class BlogService extends BaseService {
  static endPoint = "blog";

  static updateImage(id, body) {
    axios.patch(`${BASE_URL}${this.endPoint}/blogPictureUpdatebyId/${id}`,body);
  }
}
