import React, { useState } from "react";
import { useFormik } from "formik";
import { expenseSchema, pageSchema } from "../../utils/schemas";
import { toast } from "react-toastify";
import { CModal, CSelect, IconButton, Input } from "../common";
import { FaEdit } from "react-icons/fa";
import { PagesService } from "../../service";

export default function EditExpenseModal({ data, onUpdate }) {
  const categoryOpts = [{ label: "Vehicle Expense", value: "vehicle-expense" }];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(!show);

  const onSubmit = async (values) => {
    try {
      let body = {
        type: data.type,
        description: values.description,
      };
      await PagesService.update(data._id, body);
      if (onUpdate) {
        let updatedPage = data;
        updatedPage.description = values.description;
        onUpdate(data._id, updatedPage);
      }
      toast.success("Page data successfully updated!");
      handleClose();
    } catch (error) {
      toast.error("Error while updating the page details!");
    }
  };

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: data,
    validationSchema: pageSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <IconButton icon={<FaEdit className="menu-icon text-warning"/>} onClick={handleClose} />
      <CModal
        title={`Edit ${
          data.type.charAt(0).toUpperCase() + data.type.slice(1)
        } Page Details`}
        show={show}
        handleClose={handleClose}
        onFormSubmit={handleSubmit}
        submitText="Update"
      >
        <Input
          type="textarea"
          label="Description"
          name="description"
          placeholder="Enter Description"
          value={values.description}
          onChange={handleChange}
          errors={errors.description}
          touched={touched.description}
        />
      </CModal>
    </>
  );
}
