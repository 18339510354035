import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

export default function CModal({
  title,
  show,
  handleClose,
  onFormSubmit,
  hasFooter = true,
  submitText = "Save",
  children,
  isSubmitting = false
}) {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {hasFooter && (
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" onClick={onFormSubmit} disabled={isSubmitting}>
            {isSubmitting && <Spinner size="sm"/>} {submitText}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
