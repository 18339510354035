import { BrowserRouter as Router, Routes } from "react-router-dom";
import { getRoutes } from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-quill/dist/quill.snow.css';
import "./App.scss";

function App() {
  return (
    <>
      <Router>
        <Routes>{getRoutes()}</Routes>
      </Router>
      <ToastContainer position="bottom-right" /> {/* Set the position to "bottom-right" */}
    </>
  );
}

export default App;
