import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const isToken = localStorage.getItem("deltaf-token") ? true : false;
  if (isToken) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}
