import React from "react";
import { PublicLayout } from "../layouts";
import { Input } from "../components";
import { HiOutlineEnvelope, HiOutlineLockClosed } from "react-icons/hi2";
import { Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../utils/schemas";
import { AdminService } from "../service";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();

  const onLogin = async () => {
    try {
      var json = {
        email: values.email,
        password: values.password,
      };
      var response = await AdminService.create(json);
      localStorage.setItem("deltaf-token", response.data.token);
      navigate("/");
      toast.success("Logged In Successfully!");
    } catch (error) {
      toast.error(error.response.data.error);
      console.log(error.response);
    }
  };

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      isAdmin: false,
    },
    validationSchema: loginSchema,
    onSubmit: onLogin,
  });
  return (
    <PublicLayout>
      <h2 className="heading-blue mb-3">Login</h2>
      <form onSubmit={handleSubmit}>
        <Input
          label="Email"
          icon={<HiOutlineEnvelope size={30} />}
          placeholder="Enter Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          errors={errors.email}
          touched={touched.email}
        />
        <Input
          label="Password"
          type="password"
          icon={<HiOutlineLockClosed size={30} />}
          placeholder="Enter Password"
          name="password"
          value={values.password}
          onChange={handleChange}
          errors={errors.password}
          touched={touched.password}
        />
        <Link to="/forgot-password" className="link d-block text-end">
          Forgot Password?
        </Link>
        {/* <CheckInput
          inputLabel="Login as Admin"
          name="isAdmin"
          checked={values.isAdmin}
          onChange={(e) => setFieldValue("isAdmin", e.target.checked)}
          errors={errors.isAdmin}
          touched={touched.isAdmin}
        /> */}
        <div className="text-center">
          <Button type="submit" disabled={isSubmitting}>
            <span>Login</span>
            {isSubmitting && (
              <Spinner animation="border" size="sm" className="ms-2" />
            )}
          </Button>
        </div>
      </form>
    </PublicLayout>
  );
}
