import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid Email!").required("Email is required!"),
  password: yup
    .string()
    .min(8, "Password must be atleast 8 characters long!")
    .required("Password is required!"),
});

export const sendEmailSchema = yup.object().shape({
  email: yup.string().email("Invalid Email!").required("Email is required!"),
});

export const resetPassSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be atleast 8 characters long!")
    .required("Password is required!"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match!"),
});
