import React, { useState } from "react";
import { CModal, IconButton } from "../common";
import { FaEye } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";

export default function ViewContactModal({ data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);
  return (
    <>
      <IconButton icon={<FaEye className="text-success"/>} onClick={handleClose} />
      <CModal
        title="Message Details"
        show={show}
        handleClose={handleClose}
        hasFooter={false}
      >

        <Row>
          <Col md="6">

            <p className="mb-2">
              <span className="fw-semibold">ID: </span>
              {data._id}
            </p>

            <p className="mb-2">
              <span className="fw-semibold">Subject: </span>
              {data.subject}
            </p>

          </Col>

          <Col md="6">

            <p className="mb-2">
              <span className="fw-semibold">Name: </span>
              {data.name}
            </p>

            <p className="mb-2">
              <span className="fw-semibold">Email: </span>
              {data.email}
            </p>
          </Col>

          {
            data.message && data.message !== "NA" && <>
              <span className="fw-semibold">Message: </span>
              <p className="mb-2">{data.message}</p>
            </>
          }
        </Row>

      </CModal>
    </>
  );
}
