import axios from "axios";
import BaseService from "./BaseService";
import { BASE_URL } from "../axios";


export default class NewsService extends BaseService {
  static endPoint = "news";
  

static updateImage(id, body) {
  axios.patch(
    `${BASE_URL}${this.endPoint}/newsPictureUpdatebyId/${id}`, 
    body
  );
}
}