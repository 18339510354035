import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { NavLink } from "react-router-dom";

export default function SubMenu({ subMenu }) {
  const [subItems, setSubItems] = useState(false);
  const toggleSubItems = () => setSubItems(!subItems);

  return (
    <>
      <button className="submenu-btn" onClick={toggleSubItems}>
        {subItems ? <FiChevronUp size={25} /> : <FiChevronDown size={25} />}
      </button>
      {subMenu.map((subItem, i) => {
        return (
          <div
            key={i}
            className={`border-bottom my-2 ${!subItems && "d-none"}`}
          >
            <NavLink to={subItem.link} className="nav-link">
              <div className="d-flex align-items-center gap-3">
                <span className="subitem-icon">{subItem.icon}</span>
                <span>{subItem.title}</span>
              </div>
            </NavLink>
          </div>
        );
      })}
    </>
  );
}
