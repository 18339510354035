import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../layouts";
import {
  EditPageModal,
  Loader,
  Titlebar,
} from "../components";
import DataTable from "react-data-table-component";
// import { HiOutlineSearch } from "react-icons/hi";
import {  PagesService } from "../service";
import { toast } from "react-toastify";

export default function Agents() {
  const [pending, setPending] = useState(true);
  const [pages, setPages] = useState([]);
  const[filteredPages, setFilteredPages] = useState([]);

  const columns = [
    {
      name: "Page",
      selector: (row) => row.type.toUpperCase(),
    },
    {
      name: "Description",
      selector: (row) => row.description.slice(0,15) + '...',
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <EditPageModal data={row} onUpdate={onUpdate}/>
            {/* <DeleteModal itemName="Contact" id={row._id} onDelete={onDelete} /> */}
          </div>
        );
      },
    },
  ];


  const onDelete = async (id) => {
    try{
      await PagesService.delete(id);
      let list = pages.filter((item) => item._id != id);
      setPages(list);
      list = setFilteredPages.filter((item) => item._id != id);
      setFilteredPages(list);
      toast.success("Page successfully deleted!");
    } catch(error){
      toast.error("Error while deleting page!");
    }
  }
  
  const onUpdate = (id, data) => {
    let list = pages.map((item) => {
      if(item._id == id){
        return data
      }
      return item;
    });
    setPages(list);
  }

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        let response = await PagesService.get();
        let result = response.data.data;
        setPages(result);
        setFilteredPages(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      } finally {
        setPending(false);
      }
    };
    fetchContacts();
  }, []);

  return (
    <ProtectedLayout>
      <Titlebar title="Pages">
      </Titlebar>
      <DataTable
        columns={columns}
        data={filteredPages}
        progressPending={pending}
        progressComponent={<Loader />}
        pagination
        responsive
      />
    </ProtectedLayout>
  );
}
