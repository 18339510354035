import React, { useState } from "react";
import { CModal, IconButton, Input } from "../common";
import { FaEye } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import {ASSETS_BASE_URL} from "../../service/axios"

export default function ViewAgentModal({ data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);
  return (
    <>
      <IconButton icon={<FaEye className="text-success"/>} onClick={handleClose} />
      <CModal
        title="Agent Details"
        show={show}
        handleClose={handleClose}
        hasFooter={false}
      >
        <Row>
          <Col md="8">
            <p className="mb-2">
              <span className="fw-semibold">ID: </span>
              {data._id}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">Name: </span>
              {data.name}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">Email: </span>
              {data.email}
            </p>

            <p className="mb-2">
              <span className="fw-semibold">Designation: </span>
              {data.designation}
            </p>

            <p className="mb-2">
              <span className="fw-semibold">License: </span>
              {data.license}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">Phone: </span>
              {data.phone}
            </p>
          </Col>
          <Col md="4">
          <div className="d-flex gap-2">
            <img
              src={
                data.image == "avatar.jpg"
                  ? "/images/avatar.jpg"
                  : ASSETS_BASE_URL + data.image
              }
              style={{
                width: "150px",
                height: "150px",
                // objectFit: "cover",
                paddingRight: "12px"
              }}
            />
          </div>
            
          </Col>
        </Row>
        {data.description && data.description !== "NA" && (
          <>
            <span className="fw-semibold">Description: </span>
            <Input
              type="textarea"
              value={data.description}
              rows={6}
              readonly={true}
            />
          </>
        )}
      </CModal>
    </>
  );
}
