import React, { useEffect } from "react";
import Sidebar from "./partials/Sidebar";

export default function ProtectedLayout({ children }) {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <div className="protected-layout d-flex">
      <Sidebar />
      <div className="content bg-primary-light p-4">{children}</div>
    </div>
  );
}
