import { Navigate } from "react-router-dom";

export default function GuestRoute({ children }) {
  const isToken = localStorage.getItem("deltaf-token") ? true : false;
  if (isToken) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}
