import React from "react";
import { Input } from "../common";
import { Button } from "react-bootstrap";
import { HiOutlineLockClosed } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { resetPassSchema } from "../../utils/schemas";
import { useFormik } from "formik";

export default function ResetPassword() {
  const navigate = useNavigate();
  const onResetPassword = () => {
    navigate("/login");
  };

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPassSchema,
    onSubmit: onResetPassword,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Password"
        type="password"
        icon={<HiOutlineLockClosed size={30} />}
        placeholder="Enter Password"
        name="password"
        value={values.password}
        onChange={handleChange}
        errors={errors.password}
        touched={touched.password}
      />
      <Input
        label="Confirm Password"
        type="password"
        icon={<HiOutlineLockClosed size={30} />}
        placeholder="Enter Password Again"
        name="confirmPassword"
        value={values.confirmPassword}
        onChange={handleChange}
        errors={errors.confirmPassword}
        touched={touched.confirmPassword}
      />
      <div className="text-center">
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}
