import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdDashboard, MdOutlineLogout } from "react-icons/md";
import { FaCircle, FaList, FaUsers, FaEdit,FaUserTie, FaNewspaper } from "react-icons/fa";
import SubMenu from "./SubMenu";

export default function NavMenu() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("deltaf-token");
    navigate("/login");
  };

  const navMenu = [
    {
      link: "/",
      icon: <MdDashboard className="menu-icon" style={{color: "#800080"}} />,
      title: "Dashboard",
      subMenu: [],
    },
    {
      link: "/contacts",
      icon: <FaUserTie className="menu-icon" style={{color: "#009ef7"}} />,
      title: "Messages",
      subMenu: [],
    },
    {
      link: "/applyform",
      icon: <FaEdit className="menu-icon text-danger" />,
      title: "Apply Forms",
      subMenu: [],
    },
    {
      link: "/pages",
      icon: <FaList className="menu-icon" style={{color: "#009ef7"}} />,
      title: "Pages",
      subMenu: [],
    },
    {
      link: "/agents",
      icon: <FaUsers className="menu-icon text-success" />,
      title: "Agents",
      subMenu: [
        {
          link: "/agent-inquiries",
          icon: <FaCircle className="menu-icon" />,
          title: "Inquiries",
        },
      ],
    },
    {
      link: "/blogs",
      icon: <FaEdit className="menu-icon text-warning"/>,
      title: "Blogs",
      subMenu: [
        {
          link: "/add-blog",
          icon: <FaCircle className="menu-icon" />,
          title: "Add New",
        },
      ],
    },

    {
      link: "/news",
      icon: <FaNewspaper className="menu-icon text-warning"/>,
      title: "News",
      subMenu: [
        {
          link: "/add-news",
          icon: <FaNewspaper className="menu-icon" />,
          title: "Add New",
        },
      ],
    },
    {
      link: "/rate",
      icon: <FaEdit className="menu-icon text-warning" />,
      title: "Rates",
      subMenu: [],
    },
    {
      link: "/logout",
      icon: <MdOutlineLogout className="menu-icon" />,
      title: "Logout",
      onClick: () => {
        handleLogout();
      },
    },
  ];

  return (
    <div className="nav-menu">
      {navMenu.map((navitem, i) => {
        if (navitem.onClick !==undefined) {
          return (
            <div className="nav-item">
              <div
                key={i}
                style={{ cursor: "pointer" }}
                onClick={navitem.onClick}
                className={`nav-link d-flex gap-3 align-items-center`}
              >
                <span>{navitem.icon}</span>
                <span>{navitem.title}</span>
              </div>
            </div>
          );
        } else {
          return (
            <div className="nav-item position-relative">
              <NavLink
                key={i}
                to={navitem.link}
                className="nav-link d-flex gap-3 align-items-center"
              >
                <span>{navitem.icon}</span>
                <span>{navitem.title}</span>
              </NavLink>
              {navitem.subMenu.length !== 0 && (
                <SubMenu subMenu={navitem.subMenu} />
              )}
            </div>
          );
        }
      })}
    </div>
  );
}
