import React from "react";

export default function PublicLayout({ children }) {
  return (
    <div className="public-layout d-flex flex-column flex-lg-row">
      <div className="side static-side d-flex align-items-center justify-content-center p-4 p-lg-0">
        <div className="text-center text-light">
          <img src="/images/logo.png" alt="Logo" className="logo" />
          <h2 className="fw-semibold">Authoriozed Person Login Only.</h2>
          <img src="/images/water-bottles.png" alt="" className="bottles-img" />
        </div>
      </div>
      <div className="side d-flex align-items-center">
        <div className="w-100 px-4 px-lg-5 py-4 py-lg-0">{children}</div>
      </div>
    </div>
  );
}
