import React from "react";
import { Card, Col, Row } from "react-bootstrap";
export default function DataIconBox({ icon, amount, title }) {
  return (
    <Col md="4" className="mb-4">
      <Card className="border-0 shadow-sm rounded-4">
        <Card.Body className="data-icon-box w-100">
          <div className="d-flex flex-column">
              <span className="icon p-2 rounded mb-2">{icon}</span>
              <p className="mb-0 fs-2 fw-bold">
                {Math.abs(amount) > 999
                  ? Math.sign(amount) * (Math.abs(amount) / 1000).toFixed(1) + "k"
                  : Math.sign(amount) * Math.abs(amount)}
              </p>
              <span>{title}</span>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
