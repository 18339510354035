import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../layouts";
import {
  AddAgentModal,
  DeleteModal,
  Input,
  Loader,
  Titlebar,
  ViewAgentModal,
  EditAgentModal,
} from "../components";
import { DropdownButton, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { HiOutlineSearch } from "react-icons/hi";
import { ASSETS_BASE_URL, AgentService } from "../service";
import { toast } from "react-toastify";

export default function Agents() {
  const [pending, setPending] = useState(true);
  const [agents, setAgents] = useState([]);
  const [selectedAgentType, setSelectedAgentType] = useState("Agents");
  const [filteredAgents, setFilteredAgents] = useState([]);

  useEffect(() => {
    fetchData("getAgent");
  }, [agents]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <img
              src={
                row.image === "avatar.jpg"
                  ? "/images/avatar.jpg"
                  : ASSETS_BASE_URL + row.image
              }
              style={{
                width: "30px",
                height: "30px",
                objectFit: "cover",
              }}
            />
            <span>{row.name}</span>
          </div>
        );
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "License",
      selector: (row) => row.license,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Actions",
      cell: (row) => {
        let type = selectedAgentType;
        return (
          <div className="d-flex gap-2">
            <ViewAgentModal data={row} />
            <EditAgentModal data={row} type={type} />
            <DeleteModal itemName="Agent" id={row._id} onDelete={onDelete} />
          </div>
        );
      },
    },
  ];

  const onAdd = (item) => {
    let list = [...agents, item];
    setAgents(list);
    setFilteredAgents(list);
  };

  const onSearchRecord = (e) => {
    let newData = agents.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredAgents(newData);
  };

  const onDelete = async (id) => {
    try {
      AgentService.seEndPoint("deleteAgentById")
      await AgentService.delete(id);
      await AgentService.removeEndPoint();
      let list = agents.filter((item) => item._id !== id);
      setAgents(list);
      list = filteredAgents.filter((item) => item._id !== id);
      setFilteredAgents(list);
      toast.success("Agent successfully deleted!");
    } catch (error) {
      toast.error("Error while deleting agent data!");
    }
  };

  const fetchData = async (type) => {
    try {
      setPending(true);
      const agentResponse = await AgentService.getByEndPoint(type);
      console.log("My Response", agentResponse);
      setFilteredAgents(agentResponse.data.data);
    } catch (error) {
      toast.error("Error while fetching data!");
      console.log(error);
    } finally {
      setPending(false);
    }
  };

  const handleAgentTypeChange = (type) => {
    setSelectedAgentType(type);
    switch (type) {
      case "Agents":
        fetchData("getAgent");
        break;
      case "Directors":
        fetchData("director");
        break;
      case "Managers":
        fetchData("manger");
        break;
      default:
        fetchData(agents); // Show all agents by default
        break;
    }
  };

  return (
    <ProtectedLayout>
      <Titlebar title="Agents">
        <div className="d-flex gap-3 align-items-center justify-content-end">
          {/* <Input
            icon={<HiOutlineSearch size={24} />}
            placeholder="Search Name..."
            onChange={onSearchRecord}
            mainClass="mb-0"
          /> */}
          <DropdownButton
            title={`Select ${selectedAgentType}`}
            onSelect={handleAgentTypeChange}
          >
            <Dropdown.Item eventKey="Agents">Agents</Dropdown.Item>
            <Dropdown.Item eventKey="Directors">Directors</Dropdown.Item>
            <Dropdown.Item eventKey="Managers">Managers</Dropdown.Item>
          </DropdownButton>

          <AddAgentModal onAdd={onAdd} />
        </div>
      </Titlebar>
      <DataTable
        columns={columns}
        data={filteredAgents}
        progressPending={pending}
        progressComponent={<Loader />}
        pagination
        responsive
      />
    </ProtectedLayout>
  );
}
