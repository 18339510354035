import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { agentSchema } from "../../utils/schemas";
import { Button, Col, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { CModal, ImageInput, Input } from "../common";
import { AgentService } from "../../service";

export default function AddAgentModal({ onAdd }) {
  const initialFormValues = {
    name: "",
    email: "",
    phone: "",
    license: "",
    designation: "",
    image: null,
    description: "",
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(!show);

  const onSubmit = async (values) => {
    try {
      let form = new FormData();
      form.append("file", values.image);
      Object.keys(values).forEach((key) => {
        if (key !== "image") {
          form.append(key, values[key]);
        }
      });
      // AgentService.seEndPoint("registerAgent");
      let response = await AgentService.createWithEndPoint("registerAgent",form);
      console.log(response);
      if (onAdd) {
        onAdd(response.data.data);
      }
      toast.success("Agent data successfully stored!");
      handleClose();
      //Reset Form
      setValues(initialFormValues);
      setErrors({});
      setTouched({});
    } catch (error) {
      toast.error(error.response.data.message);
      // console.log(error.response.data.message);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setErrors,
    setTouched,
    errors,
    touched,
  } = useFormik({
    initialValues: initialFormValues,
    validationSchema: agentSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Button className="d-none d-lg-block" onClick={handleClose}>
        Add New
      </Button>
      <Button className="d-block d-lg-none" onClick={handleClose}>
        <FaPlus size={12} />
      </Button>
      <CModal
        title="Add New Agent"
        show={show}
        handleClose={handleClose}
        onFormSubmit={handleSubmit}
      >
        <Row>
          <Col md={3}>
            <ImageInput
              label="Agent Image"
              name="image"
              errors={errors.image}
              touched={touched.image}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col md={9}>
            <Input
              label="Name"
              name="name"
              placeholder="Enter Name"
              value={values.name}
              onChange={handleChange}
              errors={errors.name}
              touched={touched.name}
            />
            <Input
              label="Email"
              name="email"
              placeholder="Enter Email"
              value={values.email}
              onChange={handleChange}
              errors={errors.email}
              touched={touched.email}
            />
             <Input
              label="Designation"
              name="designation"
              placeholder="Enter Designation"
              value={values.designation}
              onChange={handleChange}
              errors={errors.designation}
              touched={touched.designation}
            />
            <Input
              label="phone"
              name="phone"
              placeholder="Enter phone"
              value={values.phone}
              onChange={handleChange}
              errors={errors.phone}
              touched={touched.phone}
            />
            <Input
              label="license"
              name="license"
              placeholder="Enter license"
              value={values.license}
              onChange={handleChange}
              errors={errors.license}
              touched={touched.license}
            />
            <Input
              type="textarea"
              label="Description"
              name="description"
              placeholder="Enter Description"
              value={values.description}
              onChange={handleChange}
              errors={errors.description}
              touched={touched.description}
            />
          </Col>
        </Row>
      </CModal>
    </>
  );
}
