import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../../layouts";
import { ImageInput, Input, Loader, Titlebar } from "../../components";
import { useFormik } from "formik";
import { blogSchema } from "../../utils/schemas";
import { Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import { ASSETS_BASE_URL, BlogService } from "../../service";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function AddBlog() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const onSubmit = async (values) => {
    try {
      let form = new FormData();
      if (values.image instanceof File) {
        form.append("file", values.image);
        await BlogService.updateImage(id, form);
      }
      Object.keys(values).forEach((key) => {
        if (key !== "image") {
          form.append(key, values[key]);
        }
      });
      await BlogService.update(id, form);
      toast.success("Blog successfully updated!");
      navigate("/blogs");
    } catch (error) {
      toast.error("Error while storing!");
    }
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: null,
    },
    validationSchema: blogSchema,
    onSubmit: onSubmit,
  });

  // useEffect(() => {
  //   const fetchBlog = async () => {
  //     try {
  //       let response = await BlogService.getById(id);
  //       let result = response.data.data;
  //       setFieldValue("title", result.title);
  //       setFieldValue("description", result.description);
  //       setFieldValue(
  //         "image",
  //         result.image === "avatar.jpg"
  //           ? "/images/avatar.jpg"
  //           : ASSETS_BASE_URL + result.image
  //       );
  //     } catch (error) {
  //       toast.error("Error while fetch the blog!");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchBlog();
  // }, []);
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        let response = await BlogService.getById(id);
        console.log("console data", response)
        let result = response.data.data;
        setFieldValue("title", result.title);
        setFieldValue("description", result.description);
        setFieldValue(
          "image",
          result.image === "avatar.jpg"
            ? "/images/avatar.jpg"
            : ASSETS_BASE_URL + result.image
        );
      } catch (error) {
        toast.error("Error while fetching the blog!");
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlog();
  }, [id, setFieldValue]); // Include 'id' and 'setFieldValue' in the dependency array
  

  return (
    <ProtectedLayout>
      <Titlebar title="Edit Blog" />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center py-3">
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <ImageInput
            label="Cover Photo"
            name="image"
            value={values.image}
            errors={errors.image}
            touched={touched.image}
            setFieldValue={setFieldValue}
          />
          <Input
            label="Title"
            placeholder="Enter Title"
            name="title"
            value={values.title}
            errors={errors.title}
            touched={touched.title}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="mb-3">
            <div className="bg-white">
              <ReactQuill
                theme="snow"
                value={values.description}
                onChange={(value) => setFieldValue("description", value)}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, 0] }],
                    ["bold", "italic", "underline"],
                    ["image", "code-block"],
                    [{ header: 1 }, { header: 2 }], // Additional header styles
                    [{ list: "ordered" }, { list: "bullet" }], // Ordered and unordered lists
                    ["link", "blockquote", "code"], // Link, blockquote, and code
                    [{ align: [] }], // Text alignment
                    [{ indent: "-1" }, { indent: "+1" }], // Indentation
                    ["table"], // Table module
                  ],
                }}
                placeholder="Compose an epic..."
              />
            </div>
            {errors.description && touched.description && (
              <p className="text-danger mt-1 mb-0">{errors.description}</p>
            )}
          </div>
          <div className="text-end">
            <Button type="submit">Save</Button>
          </div>
        </form>
      )}
    </ProtectedLayout>
  );
}
