import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../../layouts";
import {
  DeleteModal,
  IconButton,
  Input,
  Loader,
  Titlebar,
} from "../../components";
import DataTable from "react-data-table-component";
import { HiOutlineSearch } from "react-icons/hi";
import { ASSETS_BASE_URL, BlogService } from "../../service";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


const EditBlog = ({id}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/blog/${id}`);
  }
  return (
    <IconButton icon={<FaEdit className="menu-icon text-warning"/>} onClick={handleClick} />
  )
}
export default function Blogs() {
  const [pending, setPending] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <img
              src={
                row.image === "avatar.jpg"
                  ? "/images/avatar.jpg"
                  : ASSETS_BASE_URL + row.image
              }
              style={{
                width: "30px",
                height: "30px",
                objectFit: "cover",
              }}
            />
            <span>{row.title}</span>
          </div>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <EditBlog id={row._id}/>
            <DeleteModal itemName="Blog" id={row._id} onDelete={onDelete} />
          </div>
        );
      },
    },
  ];


  const onDelete = async (id) => {
    try{
      await BlogService.delete(id);
      let list = blogs.filter((item) => item._id !== id);
      setBlogs(list);
      setFilteredBlogs(list);
      toast.success("Blog successfully deleted!");
    } catch(error){
      toast.error("Error while deleting blog data!");
    }
  }

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        let response = await BlogService.get();
        let result = response.data.data;
        setBlogs(result);
        setFilteredBlogs(result);
      } catch (error) {
        console.log(error);
        toast.error("Error while fecthing data!");
      } finally {
        setPending(false);
      }
    };
    fetchAgents();
  }, []);

  return (
    <ProtectedLayout>
      <Titlebar title="Blogs">
      </Titlebar>
      <DataTable
        columns={columns}
        data={filteredBlogs}
        progressPending={pending}
        progressComponent={<Loader />}
        pagination
        responsive
      />
    </ProtectedLayout>
  );
}
