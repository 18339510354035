import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loader() {
  return (
    <div className="py-3">
      <Spinner animation="border" size="sm" />
      <span className="ms-2">Loading...</span>
    </div>
  );
}
