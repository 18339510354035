import React, { useState } from "react";
import { CModal, IconButton } from "../common";
import { FaEye } from "react-icons/fa";
import { ASSETS_BASE_URL } from "../../service";

export default function ViewContactModal({ data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);
  return (
    <>
      <IconButton icon={<FaEye className="text-success"/>} onClick={handleClose} />
      <CModal
        title="Inquiry Details"
        show={show}
        handleClose={handleClose}
        hasFooter={false}
      >
        <h5>Inquiry:</h5>
        <p className="mb-2">
          <span className="fw-semibold">ID: </span>
          {data._id}
        </p>
        <p className="mb-2">
          <span className="fw-semibold">Name: </span>
          {data.name}
        </p>
        <p className="mb-2">
          <span className="fw-semibold">Email: </span>
          {data.email}
        </p>
        <p className="mb-2">
          <span className="fw-semibold">Subject: </span>
          {data.subject}
        </p>
        {data.message && data.message !== "NA" && (
          <>
            <span className="fw-semibold">Message: </span>
            <p className="mb-2">{data.message}</p>
          </>
        )}
        <hr />
        {data.agentId && (
          <>
            <h5>Agent:</h5>
            <img
              src={
                data.agentId.image == "avatar.jpg"
                  ? "/images/avatar.jpg"
                  : ASSETS_BASE_URL + data.agentId.image
              }
              style={{
                width: "70px",
                height: "70px",
                objectFit: "cover",
              }}
            />
            <p className="mb-2">
              <span className="fw-semibold">Name: </span>
              {data?.agentId.name}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">Email: </span>
              {data?.agentId.email}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">Phone: </span>
              {data?.agentId.phone}
            </p>
            <p className="mb-2">
              <span className="fw-semibold">License: </span>
              {data?.agentId.license}
            </p>
          </>
        )}
      </CModal>
    </>
  );
}
