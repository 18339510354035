import axios from "axios";

// export const BASE_URL = "http://localhost:4002/api/";
//
export const BASE_URL = "https://api.deltaf.ca/api/";

// export const ASSETS_BASE_URL = "http://localhost:4002/";

export const ASSETS_BASE_URL = "https://mortgageimage.s3.eu-west-2.amazonaws.com/";

export const imageUploadService = (file) => {
  const form = new FormData();
  form.append("file", file);
  return axios.post(BASE_URL + "image/", form);
};



export default axios;
