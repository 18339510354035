import React from "react";

export default function Input({
  label,
  icon,
  type = "text",
  mainClass,
  className,
  errors,
  touched,
  ...props
}) {
  return (
    <div className={`all-inputs-field ${mainClass ? mainClass : "mb-3"}`}>
      {label && <label className="mb-1">{label}</label>}
      <div className="field">
        {icon && <span className="input-icon">{icon}</span>}
        {type === "textarea" ? (
          <textarea
            rows={3}
            className={`form-control ${icon && "with-icon"} ${className}`}
            {...props}
          />
        ) : (
          <input
            type={type}
            className={`form-control ${icon && "with-icon"} ${className}`}
            {...props}
          />
        )}
      </div>
      {errors && touched && <p className="text-danger mt-1 mb-0">{errors}</p>}
    </div>
  );
}
