import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../../layouts";
import {
  DeleteModal,
  IconButton,
  Input,
  Loader,
  Titlebar,
} from "../../components";
import DataTable from "react-data-table-component";
import { HiOutlineSearch } from "react-icons/hi";
import {ASSETS_BASE_URL, NewsService } from "../../service";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


const EditNews = ({id}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/news/${id}`);
  }
  return (
    <IconButton icon={<FaEdit className="menu-icon text-warning"/>} onClick={handleClick} />
  )
}
export default function News() {
  const [pending, setPending] = useState(true);
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);

  const columns = [
  
    {
      name: "Heading",
      selector: (row) => row.title,
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <img
              src={
                row.image === "avatar.jpg"
                  ? "/images/avatar.jpg"
                  : ASSETS_BASE_URL + row.image
              }
              style={{
                width: "30px",
                height: "30px",
                objectFit: "cover",
              }}
            />
            <span>{row.heading}</span>
          </div>
        );
      },
    },

    {
      name: "Description",
      selector: (row) => row.title,
      cell: (row) => {
        const maxLength = 50; // Set your desired maximum length here
        const truncatedDescription = row.description.length > maxLength ? row.description.substring(0, maxLength) + '...' : row.description;
        return (
          <div className="d-flex gap-2">
            <span>{truncatedDescription}</span>
          </div>
        );
      },
      width: "450px",
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex gap-2">
            <EditNews id={row._id}/>
            <DeleteModal itemName="News" id={row._id} onDelete={onDelete} />
          </div>
        );
      },
    },
  ];

 

  // const onSearchRecord = (e) => {
  //   let newData = news.filter((item) =>
  //     item.title.toLowerCase().includes(e.target.value.toLowerCase())
  //   );
  //   setFilteredNews(newData);
  // };

  const onDelete = async (id) => {
    try{
      await NewsService.delete(id);
      let list = news.filter((item) => item._id !== id);
      setNews(list);
      setFilteredNews(list);
      toast.success("News successfully deleted!");
    } catch(error){
      toast.error("Error while deleting news data!");
    }
  }

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        console.log("console data")
        let response = await NewsService.get();
        let result = response.data.data;
        setNews(result);
        setFilteredNews(result);
      } catch (error) {
        toast.error("Error while fecthing data!");
      } finally {
        setPending(false);
      }
    };
    fetchAgents();
  }, []);

  return (
    <ProtectedLayout>
      <Titlebar title="News">
        {/* <div className="d-flex gap-3 align-items-center justify-content-end">
          <Input
            icon={<HiOutlineSearch size={24} />}
            placeholder="Search Title..."
            onChange={onSearchRecord}
            mainClass="mb-0"
          />
        </div> */}
      </Titlebar>
      <DataTable
        columns={columns}
        data={filteredNews}
        progressPending={pending}
        progressComponent={<Loader />}
        pagination
        responsive
      />
    </ProtectedLayout>
  );
}
