import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { agentSchema } from "../../utils/schemas";
import { Col, Row } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { CModal, ImageInput, Input, IconButton } from "../common";
import { ASSETS_BASE_URL, AgentService } from "../../service";

export default function EditAgentModal({ data, type }) {
  const initialFormValues = {
    name: data.name,
    email: data.email,
    designation: data.designation,
    phone: data.phone,
    license: data.license,
    image:
      data.image === "avatar.jpg"
        ? "/images/avatar.jpg"
        : ASSETS_BASE_URL + data.image,
    description: data.description,
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(!show);

  const onSubmit = async (values) => {
    try {
      let form = new FormData();

      let urlType = "updateAgentById";
      let imageType = "agentPictureUpdatebyId";

      switch (type) {
        case "Agents":
          urlType = "updateAgentById";
          imageType = "agentPictureUpdatebyId";
          break;
        case "Directors":
          urlType = "directorUpdate";
          imageType = "directorPictureUpdatebyId";
          break;
        case "Managers":
          urlType = "mangerUpdate";
          imageType = "mangerPictureUpdatebyId";
          break;
        default:
          urlType = "updateAgentById";
          imageType = "agentPictureUpdatebyId";
          break;
      }
console.log(data._id);
      if (values.image instanceof File) {

        form.append("file", values.image);
        AgentService.updateImage(data._id, imageType, form);

      }

      Object.keys(values).forEach((key) => {
        if (key !== "image") {
          form.append(key, values[key]);
        }
      });

      let response = await AgentService.updateWithEndPoint(
        data._id,
        urlType,
        form
      );
      console.log("updated data", response);
      toast.success("Agent data successfully updated!");
      window.location.reload();
      handleClose();
      setValues(initialFormValues);
      setErrors({});
      setTouched({});
    } catch (error) {
      toast.error("Error while updating the data!");
      console.log(error);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setErrors,
    setTouched,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: initialFormValues,
    validationSchema: agentSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <IconButton
        icon={<FaEdit className="menu-icon text-warning" />}
        onClick={handleClose}
      />
      <CModal
        title="Update Agent"
        show={show}
        handleClose={handleClose}
        onFormSubmit={handleSubmit}
        submitText="Update"
        isSubmitting={isSubmitting}
      >
        <Row>
          <Col md={3}>
            <ImageInput
              value={
                data.image === "avatar.jpg"
                  ? "/images/avatar.jpg"
                  : ASSETS_BASE_URL + data.image
              }
              label="Agent Image"
              name="image"
              errors={errors.image}
              touched={touched.image}
              setFieldValue={setFieldValue}
            />
          </Col>
          <Col md={9}>
            <Input
              label="Name"
              name="name"
              placeholder="Enter Name"
              value={values.name}
              onChange={handleChange}
              errors={errors.name}
              touched={touched.name}
            />
            <Input
              label="Email"
              name="email"
              placeholder="Enter Email"
              value={values.email}
              onChange={handleChange}
              errors={errors.email}
              touched={touched.email}
            />
            <Input
              label="Designation"
              name="designation"
              placeholder="Enter Designation"
              value={values.designation}
              onChange={handleChange}
              errors={errors.designation}
              touched={touched.designation}
            />
            <Input
              label="phone"
              name="phone"
              placeholder="Enter phone"
              value={values.phone}
              onChange={handleChange}
              errors={errors.phone}
              touched={touched.phone}
            />
            <Input
              label="license"
              name="license"
              placeholder="Enter license"
              value={values.license}
              onChange={handleChange}
              errors={errors.license}
              touched={touched.license}
            />
            <Input
              type="textarea"
              label="Description"
              name="description"
              placeholder="Enter Description"
              value={values.description}
              onChange={handleChange}
              errors={errors.description}
              touched={touched.description}
            />
          </Col>
        </Row>
      </CModal>
    </>
  );
}
