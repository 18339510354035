import React, { useEffect, useState } from "react";
import { ProtectedLayout } from "../../layouts";
import { Input, Titlebar } from "../../components";
import { useFormik } from "formik";
import { rateSchema } from "../../utils/schemas";
import { Button } from "react-bootstrap";
import { RateService } from "../../service";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function AddRate() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  const onSubmit = async (values) => {
    try {
      let json = {
        rate: values.rate,
      };
      console.log(json);
      await RateService.update(id, json);
      toast.success("Rate updated successfully!");
    } catch (error) {
      toast.error("Error while updating!");
    }
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      rate: "",
    },
    validationSchema: rateSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        let response = await RateService.getById(id);
        console.log(response);
        let result = response.data.data;
        setBlog(result);
        setFieldValue("rate", result.rate);
      } catch (error) {
        toast.error("Error while fetching the rate!");
      }
    };
    fetchBlog();
  }, [setFieldValue,id]);

  return (
    <ProtectedLayout>
      <Titlebar title="Edit Rate" />
      <form onSubmit={handleSubmit}>
        <Input
          label="Rate"
          placeholder="Enter Rate"
          name="rate"
          value={values.rate}
          errors={errors.rate}
          touched={touched.rate}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="text-end">
          <Button type="submit">Update</Button>
        </div>
      </form>
    </ProtectedLayout>
  );
}
